var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('h1', [_vm._v("Mangler tilgang")]), _c('p', [_vm._v("Du har ikke tilgang til å vise denne siden.")]), _c('p', [_c('a', {
    attrs: {
      "href": "/kurs"
    }
  }, [_vm._v("Gå til kurslisten")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }